export const TWISTS = {
  // 'LM-left': {
  "L'": {
    type: 'twist',
    axis: 'x',
    direction: 'left',
    moves: {
      utl: 'btl',
      ucl: 'bcl',
      ubl: 'bbl',
      ftl: 'utl',
      fcl: 'ucl',
      fbl: 'ubl',
      dtl: 'ftl',
      dcl: 'fcl',
      dbl: 'fbl',
      btl: 'dtl',
      bcl: 'dcl',
      bbl: 'dbl',
      ltl: 'lbl',
      lcl: 'lbc',
      lbl: 'lbr',
      ltc: 'lcl',
      lbc: 'lcr',
      ltr: 'ltl',
      lcr: 'ltc',
      lbr: 'ltr',
      lcc: 'lcc',
    },
  },
  // 'LM-right': {
  L: {
    type: 'twist',
    axis: 'x',
    direction: 'right',
    moves: {
      utl: 'ftl',
      ucl: 'fcl',
      ubl: 'fbl',
      ftl: 'dtl',
      fcl: 'dcl',
      fbl: 'dbl',
      dtl: 'btl',
      dcl: 'bcl',
      dbl: 'bbl',
      btl: 'utl',
      bcl: 'ucl',
      bbl: 'ubl',
      ltl: 'ltr',
      lcl: 'ltc',
      lbl: 'ltl',
      ltc: 'lcr',
      lbc: 'lcl',
      ltr: 'lbr',
      lcr: 'lbc',
      lbr: 'lbl',
      lcc: 'lcc',
    },
  },
  // 'RM-right': {
  "R'": {
    type: 'twist',
    axis: 'x',
    direction: 'right',
    moves: {
      utr: 'ftr',
      ucr: 'fcr',
      ubr: 'fbr',
      ftr: 'dtr',
      fcr: 'dcr',
      fbr: 'dbr',
      dtr: 'btr',
      dcr: 'bcr',
      dbr: 'bbr',
      btr: 'utr',
      bcr: 'ucr',
      bbr: 'ubr',
      rtl: 'rbl',
      rcl: 'rbc',
      rbl: 'rbr',
      rtc: 'rcl',
      rcc: 'rcc',
      rbc: 'rcr',
      rtr: 'rtl',
      rcr: 'rtc',
      rbr: 'rtr',
    },
  },
  // 'RM-left': {
  R: {
    type: 'twist',
    axis: 'x',
    direction: 'left',
    moves: {
      utr: 'btr',
      ucr: 'bcr',
      ubr: 'bbr',
      ftr: 'utr',
      fcr: 'ucr',
      fbr: 'ubr',
      dtr: 'ftr',
      dcr: 'fcr',
      dbr: 'fbr',
      btr: 'dtr',
      bcr: 'dcr',
      bbr: 'dbr',
      rtl: 'rtr',
      rcl: 'rtc',
      rbl: 'rtl',
      rtc: 'rcr',
      rbc: 'rcl',
      rtr: 'rbr',
      rcr: 'rbc',
      rbr: 'rbl',
      rcc: 'rcc',
    },
  },

  // 'UE-left': {
  U: {
    type: 'twist',
    axis: 'y',
    direction: 'right',
    moves: {
      rtl: 'ftl',
      rtc: 'ftc',
      rtr: 'ftr',
      ftl: 'ltl',
      ftc: 'ltc',
      ftr: 'ltr',
      ltl: 'bbr',
      ltc: 'bbc',
      ltr: 'bbl',
      bbr: 'rtl',
      bbc: 'rtc',
      bbl: 'rtr',
      utl: 'utr',
      ucl: 'utc',
      ubl: 'utl',
      utc: 'ucr',
      ubc: 'ucl',
      utr: 'ubr',
      ucr: 'ubc',
      ubr: 'ubl',
      ucc: 'ucc',
    },
  },
  // 'UE-right': {
  "U'": {
    type: 'twist',
    axis: 'y',
    direction: 'left',
    moves: {
      ltl: 'ftl',
      ltc: 'ftc',
      ltr: 'ftr',
      ftl: 'rtl',
      ftc: 'rtc',
      ftr: 'rtr',
      rtl: 'bbr',
      rtc: 'bbc',
      rtr: 'bbl',
      bbr: 'ltl',
      bbc: 'ltc',
      bbl: 'ltr',
      utl: 'ubl',
      ucl: 'ubc',
      ubl: 'ubr',
      utc: 'ucl',
      ucc: 'ucc',
      ubc: 'ucr',
      utr: 'utl',
      ucr: 'utc',
      ubr: 'utr',
    },
  },
  // 'DE-left': {
  "D'": {
    type: 'twist',
    axis: 'y',
    direction: 'right',
    moves: {
      fbl: 'lbl',
      fbc: 'lbc',
      fbr: 'lbr',
      lbl: 'btr',
      lbc: 'btc',
      lbr: 'btl',
      btr: 'rbl',
      btc: 'rbc',
      btl: 'rbr',
      rbl: 'fbl',
      rbc: 'fbc',
      rbr: 'fbr',
      dtl: 'dbl',
      dcl: 'dbc',
      dbl: 'dbr',
      dtc: 'dcl',
      dcc: 'dcc',
      dbc: 'dcr',
      dtr: 'dtl',
      dcr: 'dtc',
      dbr: 'dtr',
    },
  },
  // 'DE-right': {
  D: {
    type: 'twist',
    axis: 'y',
    direction: 'left',
    moves: {
      fbl: 'rbl',
      fbc: 'rbc',
      fbr: 'rbr',
      rbl: 'btr',
      rbc: 'btc',
      rbr: 'btl',
      btr: 'lbl',
      btc: 'lbc',
      btl: 'lbr',
      lbl: 'fbl',
      lbc: 'fbc',
      lbr: 'fbr',
      dtl: 'dtr',
      dcl: 'dtc',
      dbl: 'dtl',
      dtc: 'dcr',
      dbc: 'dcl',
      dtr: 'dbr',
      dcr: 'dbc',
      dbr: 'dbl',
      dcc: 'dcc',
    },
  },
  // 'FS-left': {
  "F'": {
    type: 'twist',
    axis: 'z',
    direction: 'right',
    moves: {
      ubl: 'lbr',
      ubc: 'lcr',
      ubr: 'ltr',
      lbr: 'dtr',
      lcr: 'dtc',
      ltr: 'dtl',
      dtl: 'rbl',
      dtc: 'rcl',
      dtr: 'rtl',
      rbl: 'ubr',
      rcl: 'ubc',
      rtl: 'ubl',
      ftl: 'fbl',
      fcl: 'fbc',
      fbl: 'fbr',
      ftc: 'fcl',
      fcc: 'fcc',
      fbc: 'fcr',
      ftr: 'ftl',
      fcr: 'ftc',
      fbr: 'ftr',
    },
  },
  // 'FS-right': {
  F: {
    type: 'twist',
    axis: 'z',
    direction: 'left',
    moves: {
      ubl: 'rtl',
      ubc: 'rcl',
      ubr: 'rbl',
      lbr: 'ubl',
      lcr: 'ubc',
      ltr: 'ubr',
      dtl: 'ltr',
      dtc: 'lcr',
      dtr: 'lbr',
      rbl: 'dtl',
      rcl: 'dtc',
      rtl: 'dtr',
      ftl: 'ftr',
      fcl: 'ftc',
      fbl: 'ftl',
      ftc: 'fcr',
      fbc: 'fcl',
      ftr: 'fbr',
      fcr: 'fbc',
      fbr: 'fbl',
      fcc: 'fcc',
    },
  },
  // 'BS-right': {
  "B'": {
    type: 'twist',
    axis: 'z',
    direction: 'left',
    moves: {
      utl: 'rtr',
      utc: 'rcr',
      utr: 'rbr',
      rtr: 'dbr',
      rcr: 'dbc',
      rbr: 'dbl',
      dbr: 'lbl',
      dbc: 'lcl',
      dbl: 'ltl',
      lbl: 'utl',
      lcl: 'utc',
      ltl: 'utr',
      btl: 'bbl',
      bcl: 'bbc',
      bbl: 'bbr',
      btc: 'bcl',
      bcc: 'bcc',
      bbc: 'bcr',
      btr: 'btl',
      bcr: 'btc',
      bbr: 'btr',
    },
  },
  // 'BS-left': {
  B: {
    type: 'twist',
    axis: 'z',
    direction: 'right',
    moves: {
      rtr: 'utl',
      rcr: 'utc',
      rbr: 'utr',
      dbr: 'rtr',
      dbc: 'rcr',
      dbl: 'rbr',
      lbl: 'dbr',
      lcl: 'dbc',
      ltl: 'dbl',
      utl: 'lbl',
      utc: 'lcl',
      utr: 'ltl',
      btl: 'btr',
      bcl: 'btc',
      bbl: 'btl',
      btc: 'bcr',
      bbc: 'bcl',
      btr: 'bbr',
      bcr: 'bbc',
      bbr: 'bbl',
      bcc: 'bcc',
    },
  },
  'CM-right': {
    type: 'twist',
    axis: 'x',
    direction: 'right',
    moves: {
      utc: 'ftc',
      ucc: 'fcc',
      ubc: 'fbc',
      ftc: 'dtc',
      fcc: 'dcc',
      fbc: 'dbc',
      dtc: 'btc',
      dcc: 'bcc',
      dbc: 'bbc',
      btc: 'utc',
      bcc: 'ucc',
      bbc: 'ubc',
    },
  },
  'CM-left': {
    type: 'twist',
    axis: 'x',
    direction: 'left',
    moves: {
      utc: 'btc',
      ucc: 'bcc',
      ubc: 'bbc',
      ftc: 'utc',
      fcc: 'ucc',
      fbc: 'ubc',
      dtc: 'ftc',
      dcc: 'fcc',
      dbc: 'fbc',
      btc: 'dtc',
      bcc: 'dcc',
      bbc: 'dbc',
    },
  },
  'CE-right': {
    type: 'twist',
    axis: 'y',
    direction: 'left',
    moves: {
      fcl: 'rcl',
      fcc: 'rcc',
      fcr: 'rcr',
      lcl: 'fcl',
      lcc: 'fcc',
      lcr: 'fcr',
      bcl: 'lcr',
      bcc: 'lcc',
      bcr: 'lcl',
      rcl: 'bcr',
      rcc: 'bcc',
      rcr: 'bcl',
    },
  },
  'CE-left': {
    type: 'twist',
    axis: 'y',
    direction: 'right',
    moves: {
      fcl: 'lcl',
      fcc: 'lcc',
      fcr: 'lcr',
      rcl: 'fcl',
      rcc: 'fcc',
      rcr: 'fcr',
      bcl: 'rcr',
      bcc: 'rcc',
      bcr: 'rcl',
      lcl: 'bcr',
      lcc: 'bcc',
      lcr: 'bcl',
    },
  },
  'CS-left': {
    type: 'twist',
    axis: 'z',
    direction: 'right',
    moves: {
      ucl: 'lbc',
      ucc: 'lcc',
      ucr: 'ltc',
      ltc: 'dcl',
      lcc: 'dcc',
      lbc: 'dcr',
      dcl: 'rbc',
      dcc: 'rcc',
      dcr: 'rtc',
      rbc: 'ucr',
      rcc: 'ucc',
      rtc: 'ucl',
    },
  },
  'CS-right': {
    type: 'twist',
    axis: 'z',
    direction: 'left',
    moves: {
      lbc: 'ucl',
      lcc: 'ucc',
      ltc: 'ucr',
      dcl: 'ltc',
      dcc: 'lcc',
      dcr: 'lbc',
      rbc: 'dcl',
      rcc: 'dcc',
      rtc: 'dcr',
      ucr: 'rbc',
      ucc: 'rcc',
      ucl: 'rtc',
    },
  },
}

export const CENTER_TWISTS = {}

export const ROTATIONS = {
  'down-right': {
    type: 'rotation',
    moves: ['L', "R'", 'CM-right']
      .map(move => TWISTS[move].moves)
      .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  },
  'up-left': {
    type: 'rotation',
    moves: ["L'", 'R', 'CM-left']
      .map(move => TWISTS[move].moves)
      .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  },
  'sideways-left': {
    type: 'rotation',
    axis: 'y',
    moves: ['CE-left', 'U', "D'"]
      .map(move => TWISTS[move].moves)
      .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  },
  'sideways-right': {
    type: 'rotation',
    axis: 'y',
    moves: ['CE-right', "U'", 'D']
      .map(move => TWISTS[move].moves)
      .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  },
  'bottomtwo-left': {
    type: 'rotation',
    axis: 'y',
    moves: {
      ...TWISTS["D'"].moves,
      ...TWISTS['CE-left'].moves,
    },
  },
}

export default {
  ...TWISTS,
  ...CENTER_TWISTS,
  ...ROTATIONS,
}
